.tree__controller {
    padding: 10px;
    /*border: 2px solid blue;*/
    border-radius: 20px;
    position: relative;
    display: flex;
    /*overflow: hidden;*/
}

.tree__controller--dark {
    /*filter: brightness(0.5);*/
}

.tree__controller--projection {
    /*border: 2px solid red;*/
}

.tree__controller__camera-btn {
    position: absolute !important;
    top: -5px;
    left: -5px;
    border-radius: 15px;
    z-index: 1;
}

.tree__controller__delete-btn {
    position: absolute !important;
    top: -5px;
    right: -5px;
    border-radius: 15px;
    z-index: 1;
}

.tree__controller__comment-btn {
    position: absolute !important;
    top: 50px;
    right: 50px;
    border-radius: 15px;
    z-index: 1;
}

.tree__controller__pick-days-btn {
    position: absolute !important;
    top: 440px;
    left: -5px;
    border-radius: 15px;
    z-index: 1;
}

.tree__controller__info-btn {
    z-index: 1;
}
