.tree {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    background-color: #d3d3d3e6;
    /*background-color: rgba(0, 0, 255, 0.4);*/
    /*background-image: url('../../resources/images/paper.png');*/
    background-size: 100% 100%;
    /*border: 2px solid #333;*/
    border-radius: 50px;
    --shadow-color: hsl(220deg 100% 50%);
    filter: drop-shadow(1px 2px 8px var(--shadow-color));
    /*box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;*/
}

.tree--projection {
    --shadow-color: hsl(0deg 100% 50%);
}

.tree--dark {
    background-color: #1b1b1be6;
    /*filter: brightness(0.5);*/
}

.tree__header {
    width: 95%;
}

.tree__date {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}

.tree__date__input {
    margin: 0;
    width: 130px;
}

.tree__line {
    /*border: 4px double rgba(0, 0, 0, 0);*/
    padding: 4px;
    /*padding: 0;*/
    margin: 0;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tree__line--multiple {
    justify-content: space-between;
}

.tree__line__node {
    /*padding: 0;*/
    /*border: 4px double rgba(0, 0, 0, 0);*/
    padding: 4px;
    background: #fc3;
    border-radius: 15px;
    white-space: nowrap;
    position: relative;
}

.tree__line__node--main {
    /*border: 0px double rgba(0, 0, 0, 0);*/
    padding: 10px;
}

.tree__line__node--belt {
    border-top: 0;
    border-bottom: 0;
}

.tree__line__node--reliquary {
    padding-top: 20px;
}

