#calendar, .calendar {
    height: 100%;
}

.calendar .months-container .month-container {
    height: 250px !important;
}

.calendar .day-content {
    font-size: 16px;
    font-weight: bold;
}

.calendar .day-content sub {
    margin-left: 3px;
    font-size: 10px;
}
