h1.ce-header, h2.ce-header, h3.ce-header, h4.ce-header, h5.ce-header, h6.ce-header {
    display: block;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

h1.ce-header {
    font-size: 2em;
}

h2.ce-header {
    font-size: 1.5em;
}

h3.ce-header {
    font-size: 1.17em;
}

h4.ce-header {
    font-size: 1em;
}

h5.ce-header {
    font-size: 0.83em;
}

h6.ce-header {
    font-size: 0.67em;
}

/**
 * Dark mode fore editor js
 */

.article-editor.article-editor--dark .codex-editor .ce-toolbar__actions svg {
    color: white;
}

.article-editor.article-editor--dark .codex-editor .ce-toolbar__actions [class^='ce-toolbar__']:hover svg {
    color: black;
}

.article-editor.article-editor--dark .codex-editor .ce-popover,
.article-editor.article-editor--dark .codex-editor .tc-popover {
    background-color: #383838;
}

.article-editor.article-editor--dark .codex-editor .ce-popover .ce-popover__item:hover,
.article-editor.article-editor--dark .codex-editor .ce-popover .tc-popover__item:hover {
    background-color: black;
}

.article-editor.article-editor--dark .codex-editor .ce-popover .ce-popover__item .ce-popover__item-icon,
.article-editor.article-editor--dark .codex-editor .tc-popover .tc-popover__item .tc-popover__item-icon {
    background-color: black;
}

.article-editor.article-editor--dark .codex-editor .cdx-search-field .cdx-search-field__input,
.article-editor.article-editor--dark .codex-editor .cdx-search-field .cdx-search-field__input::placeholder {
    color: white;
}
